import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Loading from '../components/Loading';

const Login = lazy(() => import('../views/Login'));
const Dashboard = lazy(() => import('../views/Dashboard'));
const Connects = lazy(() => import('../views/Connects'));
const ConnectsCrear = lazy(() => import('../views/Connects/Crear'));
const ConnectsActualizar = lazy(() => import('../views/Connects/Actualizar'));
const ConnectsDetalles = lazy(() => import('../views/Connects/Detalles'));
const ConnectCrearPublico = lazy(() => import('../views/CrearConnect'));
const ConnectVencerDominio = lazy(() => import('../views/ListaConnectsVercerPorDominio'));
const ConnectVencerFirebase = lazy(() => import('../views/ListaConnectsVercerPorFirebase'));
const Usuarios = lazy(() => import('../views/Usuarios'));
const UsuariosCrear = lazy(() => import('../views/Usuarios/Crear'));
const UsuariosActualizar = lazy(() => import('../views/Usuarios/Actualizar'));

export default function App() {
    return (
        <BrowserRouter>
            <Suspense fallback={<Loading />}>
                <Routes>
                    <Route exact path="/" element={<Login />} />
                    <Route exact path="/crear-connect" element={<ConnectCrearPublico />} />
                    <Route exact path="/admin/dashboard" element={<Dashboard />} />
                    <Route exact path="/admin/connects/proximos-a-vencer-por-dominio" element={<ConnectVencerDominio />} />
                    <Route exact path="/admin/connects/proximos-a-vencer-por-firebase" element={<ConnectVencerFirebase />} />
                    <Route exact path="/admin/connects" element={<Connects />} />
                    <Route exact path="/admin/connects/crear" element={<ConnectsCrear />} />
                    <Route exact path="/admin/connects/:id/actualizar" element={<ConnectsActualizar />} />
                    <Route exact path="/admin/connect/:id/detalles" element={<ConnectsDetalles />} />
                    <Route exact path="/admin/usuarios" element={<Usuarios />} />
                    <Route exact path="/admin/usuarios/crear" element={<UsuariosCrear />} />
                    <Route exact path="/admin/usuarios/:id/actualizar" element={<UsuariosActualizar />} />
                </Routes>
            </Suspense>
        </BrowserRouter>
    )
}