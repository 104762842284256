import React from 'react';
import Logo from '../../resources/imgs/spira-connect.png';
import './styles.scss';

export default function Loading() {
    let backgroundStyles = `#root::after{background-color: #fff !important}`;
    <style>{backgroundStyles}</style>
    
    return <div className="loader">
        <img src={Logo} alt="logo" />
        <div className="outer"></div>
        <div className="middle"></div>
        <div className="inner"></div>
    </div>
}